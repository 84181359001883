.main-header .dropdown-menu a:hover {
    background: transparent;
    color: #FBB848;
}
.main-header .dropdown-menu li:hover
{
    background-color: #0C6873;
}
.main-header .dropdown-menu li.dropdown-submenu a.dropdown-toggle
{
    font-size: 14px;
    text-transform: capitalize;
}
.main-header .dropdown-menu li.dropdown-submenu a.dropdown-toggle:hover, .no-home .main-header .dropdown-menu li.dropdown-submenu a.dropdown-toggle:hover 
{
    color: #FBB848!important;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link.active 
{
    color: #FBB848!important;
}
.setting-button {
    background: #FBB848;
}
.donate-button {
    background: #FBB848;
}


.love-story-box h4 {
    color: #FBB848;
}

.close-offcanvas{
    color: #FBB848;
}

.banner .banner-slider-inner p{
    color: #FBB848;
}

.love-story-box .love-story-content {
    background:  #FBB848;
 }

#page_scroller {
    background:  #FBB848;
}

.read-more {
    color: #FBB848;
}

.tab-box .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background: #FBB848;
}

li.active.btn.filtr-button.filtr {
    color: #fff;
    background: #FBB848;
}

.filteriz-navigation li:hover {
    color: #fff;
    background: #FBB848;
}

.btn-color {
    background: #FBB848;
    border-color: #FBB848;
}

.btn-color:hover {
    background: #FBB848;
    border-color: #FBB848;
}

.blog .date-box {
    background: #FBB848;
}

.categories ul li a:hover {
    color: #FBB848;
}

.popular-tags ul li a:hover {
    border: solid 1px #FBB848;
    background: #FBB848;
    color: #fff;
}

/** MEDIA **/
@media (max-width: 992px) {
    .navbar-light .navbar-toggler {
        color: #FBB848;
    }
}

@media (min-width: 991px) {
    .sticky-header .navbar-expand-lg .navbar-nav .active .nav-link {
        color: #fff !important;
    }

    .sticky-header .navbar-expand-lg .navbar-nav .nav-link:hover {
        color: #fff !important;
        opacity: .8;
    }

    .header-shrink .navbar-expand-lg .navbar-nav .active .nav-link {
        color: #515151 !important;
    }

    .header-shrink .navbar-expand-lg .navbar-nav .nav-link:hover {
        color: #515151 !important;
    }

    .header-shrink .navbar-expand-lg .navbar-nav .nav-link:hover {
        color: #FBB848!important;
        
    }
}
.navbar .navbar-brand .fa 
{
    color: #fff;
}
.text-yellow
{
    color: #FBB848;
}
.btn-default 
{
    background-color: #fbb848!important;
    border: none!important;
    padding-top: 14px!important;
    padding-right: 26px!important;
    padding-bottom: 14px!important;
    padding-left: 26px!important;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 1em;
    font-family: "Inter",sans-serif!important;
    color: #fff;
    cursor: pointer;
}

.btn-default-outline
{
    border: 1px solid #0C6873;
    padding-top: 14px!important;
    padding-right: 26px!important;
    padding-bottom: 14px!important;
    padding-left: 26px!important;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 1em;
    font-family: "Inter",sans-serif!important;
    color: #0C6873;
    cursor: pointer;
}
.btn-default-outline:hover {
    background-color: #fbb848!important;
    border: none!important;
    padding-top: 14px!important;
    padding-right: 26px!important;
    padding-bottom: 14px!important;
    padding-left: 26px!important;
    font-weight: 700;
    font-size: 16px!important;
    line-height: 1em;
    font-family: "Inter",sans-serif!important;
    color: #fff;
    cursor: pointer;
}
input[type="submit"]:hover , .btn-default:hover
{
    background-color: #0C6873!important;
    color: #fff!important;
    cursor: pointer;
}
.d-clr 
{
    color: #fbb848;
}
.sec-clr 
{
    color: #50797D;
}
.sec-bg-clr 
{
    background-color: #0C6873;
}
.no-home .navbar .navbar-brand .fa
{
    color: #000;
}
.bg-light-blue
{
    background-color: #F0FCFE;
}
/*------------------------------------------------------------------
[Main Stylesheet]

Project: BIYE
Version: 1.1.0
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[Table of contents]
    *. Header / .main-header
    *. Banner / .banner
    *. Wedding couple /.wedding-couple
    *. Countdowns /.countdowns
    *. Our Love Story /.our-love-story
    *. Celebrate Our Love /.celebrate-our-love
    *. Wedding Events /.wedding-events
    *. Groomsmen Bridesmaid /.groomsmen-bridesmaid
    *. Portfolio Area /.portfolio-area
    *. Gift Registration /.gift-registration
    *. Rsvp /.rsvp
    *. Thank You /.thank-you
    *. Blog /.blog-section
-------------------------------------------------------------------*/
/** GLOBAL CLASSES **/

body {
    position: relative;
    font-family: 'Open Sans', sans-serif;
    color: #141313;
}

.img-fluid {
    max-width: 100% !important;
    height: auto;
}

.cookie-consent{
    bottom: 0;
    position: fixed;
    right: 0;
    border-radius: 4px;
    z-index: 9999;
    .bg-custom{
        background-color:#130f40;
        }
    .fas{
        cursor: pointer;
        font-size: 24px;
    }
}

.bg-grea{
    background: #f9f9f8;
}

.page_loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url(./assets/images/loader.gif) center no-repeat #fff;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #141313;
}

a {
    color: #37404d;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: #fbb848;
}

.form-control:focus {
    box-shadow: none;
}

p {
    font-size: 16px;
    line-height: 26px;
    color: #141313;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #000;
    font-family: 'Open Sans', sans-serif;
}

.btn-md {
    cursor: pointer;
    padding: 15px 30px 14px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 3px;
}

.col-pad {
    padding: 0;
}

.dropdown-menu {
    border-radius: 0;
}

.form-inline .btn,
.form-inline .btn:active {
    padding: 0 17px;
    font-size: 15px;
    background: none;
    border: solid 1px transparent;
    color: #212121;
}

.form-inline .btn:hover {
    background: none;
    border: solid 1px transparent;
}

.form-inline a {
    padding: 0 17px;
}

.btn {
    font-family: 'Poppins', sans-serif;
    border-radius: 3px;
}

#page_scroller {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    cursor: pointer;
    color: #FFF;
    padding: 10px 11px;
    font-size: 12px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

/*Custom Anmation*/
.bgburns .slides li:nth-of-type(odd) .slide-background {
    animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate
}

.bgburns .slides li:nth-of-type(even) .slide-background {
    animation: bgburnsWest 25s linear infinite alternate;
    -webkit-animation: bgburnsWest 25s linear infinite alternate
}

@keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px)
    }
}

@-webkit-keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px)
    }
}

@keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px)
    }
}

@-webkit-keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0)
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px)
    }
}

@media all and (max-width: 767px) {
    .flex-direction-nav {
        display: none
    }

    .bgburns .slides li .slide-background {
        -webkit-animation: none !important;
        animation: none !important
    }
}

/** Option Panel **/
.option-panel {
    z-index: 9999;
    width: 215px;
    position: fixed;
    right: 0;
    top: 20%;
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
    padding: 20px 15px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.option-panel-collased {
    right: -215px;
}

.option-panel h2 {
    font-size: 13px;
    margin: 0 0 0 0;
    text-align: center;
    padding-bottom: 10px;
    font-weight: 600;
}

.option-panel .color-plate {
    width: 40px;
    height: 40px;
    float: left;
    margin: 3px;
    border-radius: 3px;
    cursor: pointer;
}

/** Web site color start **/
.default-plate {
    background: #ff214f;
}

.blue-plate {
    background: #95c41f;
}

.yellow-plate {
    background: #ffb400;
}

.green-light-plate {
    background: #1abc9c;
}

.red-plate {
    background: #c31c1c;
}

.orange-plate {
    background: #F34B0A;
}

.yellow-light-plate {
    background: #eae328;
}

.green-light-2-plate {
    background: #0dcdbd;
}

.olive-plate {
    background: #b3c211;
}

.purple-plate {
    background: #8e44ad;
}

.blue-light-plate {
    background: #3c9dff;
}

.brown-plate {
    background: #A14C10;
}

.setting-button {
    position: absolute;
    font-size: 16px;
    text-align: center;
    width: 50px;
    height: 50px;
    line-height: 50px;
    left: -50px;
    color: #fff;
    top: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;
    display: none;
}
/** Option Panel **/

/** Overview bgi start **/
.overview-bgi {
    position: relative;
}

.overview-bgi:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.overview .btn-border {
    padding: 7px 12px !important;
    border-radius: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
}

/** Btn **/
.read-more {
    font-weight: 600;
    font-size: 15px;
}

.btn:focus, .btn:active, .btn:focus {
    box-shadow: none !important;
    outline: none;
}

/** text start **/
.text-c{
    text-align: center;
}

.text-l{
    text-align: left;
}

.text-r{
    text-align: right;
}

/** Social media start **/
.facebook:hover {
    color: #3b589e !important;
}

.twitter:hover {
    color: #56d7fe !important;
}

.linkedin:hover {
    color: #1c82ca !important;
}

.google:hover {
    color: #dc4e41 !important;
}

.rss:hover {
    color: #e78733 !important;
}

.instagram:hover {
    color: #fb3958 !important;
}

.facebook-bg {
    background: #4867aa;
}

.facebook-bg:hover {
    background: #3a5795;
}

.twitter-bg {
    background: #56d7fe;
}

.twitter-bg:hover {
    background: #2abced;
}

.google-bg {
    background: #dc4e41;
}

.google-bg:hover {
    background: #c83a2e;
}

.linkedin-bg {
    background: #1c82ca;
}

.linkedin-bg:hover {
    background: #036497;
}

/** MAIN HEADER **/
#main-header {
    width: 100%;
    background: #FFF;
    webkit-transition: height .3s ease-out, background .3s ease-out, box-shadow .5s, left .2s cubic-bezier(.645, .045, .355, 1), -webkit-transform .5s;
    transition: height .3s ease-out, background .3s ease-out, box-shadow .5s, left .2s cubic-bezier(.645, .045, .355, 1), -webkit-transform .5s;
    transition: height .3s ease-out, background .3s ease-out, transform .5s, box-shadow .5s, left .2s cubic-bezier(.645, .045, .355, 1);
    transition: height .3s ease-out, background .3s ease-out, transform .5s, box-shadow .5s, left .2s cubic-bezier(.645, .045, .355, 1), -webkit-transform .5s;
}

.main-header {
    background: #2FB4C5;
    padding-bottom: 1rem;
}

.bg-grea-2{
    background: #f9f9f8;
}

.main-header .navbar {
    padding: 0;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link {
    padding: 15px 17px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.main-header .logo img {
    height: 40px;
}

.main-header .logo {
    padding: 25px 0;
    margin-right: 50px;
    float: left;
}

a {
    transition: all .3s ease-in-out;
}

.dropdown-menu {
    min-width: 10rem;
    padding: 0;
    margin: -1px;
    font-size: 13px;
    color: #212529;
    border: 1px solid transparent;
    -webkit-transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
}

.dropdown:hover>.dropdown-menu {
    /*display: block;*/
}

.dropdown .dropdown-item:hover> .dropdown-menu {
    /*display: block;*/
    -webkit-transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
}


.main-header .dropdown-menu a {
    padding: 9px 20px;
    color: #141313;
    font-size: 14px;
    transition: all .3s ease-in-out;
}

.main-header .dropdown-menu a:hover {
    background: #f5f5f5;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 22px;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
}

.dropdown-submenu:hover> .dropdown-menu {
    /*display: block;*/
    -webkit-transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s;
    transition: opacity .4s ease 0s,transform .4s ease 0s,visibility .4s linear 0s,-webkit-transform .4s ease 0s;
}
.main-header .navbar-expand-lg .navbar-nav .dropdown-submenu .nav-link.dropdown-toggle
{
    color: #000;
}
.main-header .navbar-expand-lg .navbar-nav .nav-link {
    color: #ffffff;
    letter-spacing: normal;
}

.main-header .navbar-expand-lg .navbar-nav .nav-link:hover{
    color: #FBB848
}
.main-header .navbar-expand-lg .navbar-nav .nav-link.donate-button:hover{
    color: #ffffff!important
}
.open-offcanvas {
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    /*display: none;*/
}

/** Banner start **/
#banner {
    position: relative;
}

.banner {
    font-family: 'Poppins', sans-serif;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
}

.banner-bg{
    background: url("./assets/images/img-3.png");
    background-size: cover;
}

.item-bg{
    height: 100vh;
}

.banner-max-height img {
    max-height: 720px;
}

.banner .slider-mover-left {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    left: 10px;
    text-align: center;
    border-radius: 5px;
    border: solid 2px #b5b1b1;
    background: rgba(0, 0, 0, 0.36);
}

.banner .slider-mover-right {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    right: 10px;
    text-align: center;
    border-radius: 5px;
    border: solid 2px #b5b1b1;
    background: rgba(0, 0, 0, 0.36);
}

.banner .slider-mover-right i {
    font-size: 25px;
    color: #fff;
}

.banner .slider-mover-left i {
    font-size: 25px;
    color: #fff;
}

.carousel-control-next, .carousel-control-prev {
    opacity: .8;
}

.banner .carousel-content {
    align-self: center !important;
    margin: 0px auto;
    color: #fff;
}

.carousel-item .text-right{
    float: right;
}

.banner .banner-slider-inner h1 {
    color: #fff;
    margin-bottom: 22px;
    font-size: 85px;
    font-weight: 400;
    letter-spacing: normal;
    font-family: 'Great Vibes', cursive;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.banner .banner-slider-inner h3 {
    color: #fff;
    border-radius: 50px;
    font-size: 22px;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 3px;
}

.banner .banner-slider-inner p{
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    font-weight: 300;
    margin-bottom: 0;
    font-size: 22px;
}

.banner .banner-slider-inner .typed-text {
    font-size: 85px;
}

.banner .banner-slider-inner {
    display: inline-block;
    text-shadow: none;
    right: 0%;
    left: 0%;
    padding: 20px 50px;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: #2FB4C5;
}

.banner .carousel-bg .carousel-item {
    height: 100vh;
    background: #3e4a5b;
}

.banner_video_bg {
    height: auto;
}

.banner_video_bg .carousel-item {
    height: 100vh;
}

#particles-banner {
    width: 100%;
    height: 100vh;
    background-color: rgba(36, 42, 53, 0.7);
    position: absolute;
}

#particles-banner-wrapper .banner-slider-inner {
    background-color: transparent;
}

.banner .banner-slider-inner .love-img img{
    height: 40px;
    margin-bottom: 20px;
}

/** Sub banner start **/
.sub-banner {
    /* background: rgba(0, 0, 0, 0.04) url(../img/banner/img-2.png) top left repeat; */
    background-size: cover;
    height: 360px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    background-color: rgba(36, 42, 53, 0.7);
}

.sub-banner .breadcrumb-area {
    letter-spacing: 1px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
}

.sub-banner .breadcrumb-area h1 {
    margin: 0 0 10px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-size: 30px;
}

.sub-banner .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-size: 16px;
}

.sub-banner .breadcrumbs .active {
    margin-left: 7px;
    color: #fff;
}

.sub-banner .breadcrumbs .active:before {
    content: "\f105";
    font-family: "FontAwesome";
    font-size: 14px;
    margin-right: 7px;
    font-weight: 600;
}

.sub-banner .breadcrumbs li a {
    color: #fff;
}

.sub-banner .breadcrumbs li a:hover{
    color: #d4d4d4;
}

/** main title start **/
.main-title {
    text-align: center;
    margin-bottom: 60px;
}

.main-title h1 {
    font-family: 'Great Vibes', cursive;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #000;
}

.main-title p{
    font-size: 18px;
}

.main-title-2 {
    text-align: center;
    margin-bottom: 60px;
}

.main-title-2 h1 {
    font-family: 'Great Vibes', cursive;
    font-size: 50px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.main-title-3 h1 {
    font-family: 'Great Vibes', cursive;
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 1px;
    z-index: 1;
    margin-bottom: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
    color: #fff;
}

/** Blockquote start **/
blockquote {
    padding: 20px 20px;
    margin: 0 0 20px;
    background: #f9f6f6;
    border-left: 5px solid #212529;
}

.blockquote{
    margin: 0 0 30px;
}

blockquote footer {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #000;
}

/** Couple box start **/
.couple-box{
    text-align: center;
    max-width: 500px;
    margin: 0 auto 30px;
    position: relative;
}

.couple-box img{
    width: 100%;
    border-radius: 10px;
}

.couple-box .photo {
    position: relative;
}

.couple-box .detail{
    position: relative;
    width: 90%;
    margin: -30px 5% 30px 5%;
    padding: 30px;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    z-index: 4;
    border-radius: 10px;
}

.couple-box .detail h3{
    font-family: 'Great Vibes', cursive;
    font-weight: 600;
    font-size: 33px;
    margin-bottom: 15px;
}

.couple-box .detail p{
    margin-bottom: 20px;
    font-size: 15px;
}

.couple-box .detail .social-list li {
    display: inline-block;
    font-size: 16px;
}

.couple-box .detail .social-list li a {
    margin: 0;
    font-size: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    color: #fff;
}

/** Wedding events box start **/
.wedding-events-box{
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    min-height: 90vh;
}

.wedding-events-box .detail{
    padding: 30px 25px 25px;
}

.wedding-events-box .detail h3{
    font-size: 20px;
}

.wedding-events-box .detail h3 a{
    color: #000;
}

.wedding-events-box .detail h3 a:hover{
    color: #141313;
}

.wedding-events-box .detail p{
    font-size: 15px;
}

.wedding-events-box img{
    width: 100%;
    max-height: 50vh;
}

/** Property box start **/
.property-box {
    position: relative;
    overflow: hidden;
    background: white;
}

.property-thumbnail {
    position: relative;
}

.property-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.property-overlay {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-link {
    color: #fff;
    border: 1px solid #FFF;
    border-radius: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    text-align: center;
    margin: 3px;
    cursor: pointer;
    display: none;
}

.overlay-link:not([href]):not([tabindex]),
.overlay-link:not([href]):not([tabindex]):hover {
    color: #fff;
}

.overlay-link:hover {
    color: #fff;
}

.property-thumbnail:hover .property-overlay {
    background-color: rgba(0, 0, 0, 0.5);
}

.property-thumbnail:hover .overlay-link {
    display: inline-block;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.property-box .property-img {
    position: relative;
    display: block;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
}

.property-box .property-img img{
    width: 100%;
}

/** Gift registration Start **/
.gift-registration {
    padding: 100px 0;
    text-align: center;
}

.gift-registration .multi-carousel {
    float: left;
    overflow: hidden;
    padding: 0 15px 0;
    width: 100%;
    position: relative;
}

.gift-registration .multi-carousel .multi-carousel-inner {
    transition: 1s ease all;
    float: left;
}

.gift-registration .multi-carousel .multi-carousel-inner .item {
    float: left;
    cursor: pointer;
}

.gift-registration .multi-carousel .multi-carousel-inner .item > div {
    padding: 0 15px;
    margin: 0px;
    text-align: center;
}

.gift-registration .multi-carousel .multi-carousel-inner .item > div .carousel-img {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.gift-registration .multi-carousel .multi-carousel-inner .item > div img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.gift-registration .multi-carousel .multi-carousel-inner .item:hover img {
    -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%);
}

.gift-registration .multi-carousel .leftLst, .multi-carousel .rightLst {
    position: absolute;
    top: calc(50% - 20px);
}

.gift-registration .multi-carousel-indicator {
    width: 30px;
    height: 30px;
    line-height: 33px;
    z-index: 5;
    display: inline-block;
    right: 10px;
    text-align: center;
    background: #a5a5a5;
    border-radius: 5%;
    cursor: pointer;
}

.gift-registration .multi-carousel-indicator i {
    font-size: 20px;
    line-height: 22px;
    color: #333;
}

.gift-registration .multi-carousel .leftLst {
    left: 0;
}

.gift-registration .multi-carousel .rightLst {
    right: 0;
}

.gift-registration .multi-carousel .leftLst.over, .multi-carousel .rightLst.over {
    pointer-events: none;
    background: #ccc;
    border: none;
}

/** Groomsmen bridesmais start **/
.groomsmen-bridesmaid-box {
    margin-bottom: 30px;
    padding: 5px;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.groomsmen-bridesmaid-box .photo{
    position: relative;
    text-align: center;
}

.groomsmen-bridesmaid-box .photo .social-list {
    position: absolute;
    padding: 15px 20px 10px;
    width: 100%;
    bottom: 0;
    background-image: -moz-linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.6) 50%,rgba(0,0,0,.8) 100%);
    background-image: -ms-linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.6) 50%,rgba(0,0,0,.8) 100%);
    background-image: -o-linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.6) 50%,rgba(0,0,0,.8) 100%);
    background-image: -webkit-linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.6) 50%,rgba(0,0,0,.8) 100%);
    background-image: -webkit-gradient(linear,center top,center bottom,from(rgba(0,0,0,0)),to(rgba(74, 74, 74,0.8)));
}

.groomsmen-bridesmaid-box .photo .social-list li {
    display: inline-block;
}

.groomsmen-bridesmaid-box .photo .social-list li a {
    margin: 0 10px;
    line-height: 35px;
    font-size: 17px;
    text-align: center;
    display: inline-block;
    color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.groomsmen-bridesmaid-box .photo img{
    width: 100%;
}

.groomsmen-bridesmaid-box .details {
    text-align: center;
    padding: 20px 0 10px;
}

.groomsmen-bridesmaid-box .details h5 {
    margin-bottom: 5px;
    font-size: 18px;
}

.groomsmen-bridesmaid-box .details h5 a{
    color: #000;
}

.groomsmen-bridesmaid-box .details h5 a:hover{
    color: #141313;
}

.groomsmen-bridesmaid-box .details p {
    margin-bottom: 0;
}

/** Tab box box start **/
.tab-box .nav-pills{
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    max-width: 267px;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50px;
}

.tab-box .nav-pills .nav-link {
    color: #141313;
    float: left;
    letter-spacing: .5px;
    padding: 6px 22px 6px 22px;
    border: 0;
    border-radius: 50px;
    margin: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

/** Love story box start **/
.love-story-box .love-story-content {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    border-radius: 100%;
    color: #fff;
    font-size: 13px;
    width: 63px;
    padding: 16px 0 15px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;
}

.love-story-box .love-story-content span{
    display: block;
}

.love-story-box .love-story-content img{
    border-radius: 50px;
    width: 100%;
}

.love-story-box .photo img{
    width: 100%;
    border-radius: 3px;
}

.love-story-box .vertical-line {
    position: absolute;
    height: 100%;
    width: 1px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-left: 1px solid #e1dddd;
}

.love-story-box h2{
    margin-bottom: 10px;
    font-size: 26px;
}

.love-story-box h4{
    font-size: 14px;
    margin-bottom: 10px;
}

.love-story-box p{
    margin-bottom: 0;
    line-height: 30px;
    font-size: 15px;
}

.love-story-box .photo{
    padding: 30px 0;
}

.love-story-box .lsb-2:first-child{
    padding-top: 0;
}

.love-story-box .lsb-3:first-child{
    padding-bottom: 0;
}

/** Rsvp start **/
.rsvp .form-control {
    width: 100%;
    padding: 10px 20px;
    font-size: 15px;
    outline: 0;
    height: 55px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.rsvp .form-control:focus {
    color: #495057;
    outline: 0;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06);
}

.rsvp .form-group {
    margin-bottom: 20px;
}

.rsvp textarea {
    min-height: 200px;
}

/** Blog start **/
.blog {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: transparent;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.blog.blog-hp .detail {
    padding: 30px 0 20px;
}

.blog .date-box {
    position: absolute;
    top: 15px;
    font-size: 14px;
    border-radius: 5px 5px 5px;
    color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    left: 30px;
}

.blog .date-box::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 20px solid transparent;
    bottom: -20px;
    left: 0;
}

.blog .date-box span {
    font-family: montserrat,sans-serif;
    font-size: 18px;
    display: block;
}

.date-box span {
    font-family: montserrat,sans-serif;
    font-size: 19px;
    display: block;
    line-height: 22px;
    color: #fff;
}

.blog .detail {
    padding: 30px 25px 10px;
}

.blog .blog-photo {
    position: relative;
}

.blog .blog-photo img {
    width: 100%;
    max-height: 50vh;
}

.blog .post-meta {
    width: 100%;
    margin-bottom: 10px;
}

.blog .post-meta span {
    margin: 0 15px 0 0;
    display: inline-block;
    font-size: 14px;
    color: #141313;
    line-height: 26px;
}

.blog .post-meta span:last-child{
    margin: 0;
}

.blog .post-meta span i {
    font-size: 13px;
    margin-right: 5px;
}

.blog .detail h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}

.blog .post-meta ul li i {
    margin-right: 5px;
}

.blog .detail p {
    font-size: 15px;
    color: #141313;
}

.blog .title-3{
    font-size: 18px!important;
    margin-bottom: 10px;
}

.blog-2 .title-2{
    font-size: 22px!important;
}

.blog-2 .detail{
    padding: 30px 25px;
}

/** Categories start **/
.categories ul li {
    font-size: 15px;
    margin-bottom: 10px;
}

.categories ul li:last-child{
    margin-bottom: 0;
}

.categories ul li a {
    color: #141313;
}

/** Popular tags start **/
.popular-tags ul li a {
    float: left;
    margin: 0 5px 4px 0;
    padding: 5px 12px;
    font-size: 14px;
    text-decoration: none;
    border: solid 1px #f3f3f3;
    background: #f3f3f3;
    color: #5f5f5f;
    border-radius: 2px;
}

/** Popular postes start **/
.popular-postes .media img {
    width: 60px;
    border-radius: 2px;
}

.popular-postes .media p {
    margin: 0;
    margin-bottom: 5px;
    line-height: 22px;
    color: #000;
    font-weight: 600;
    font-size: 15px;
}

.popular-postes .media p:last-child {
    color: #141313;
    font-size: 14px;
    font-weight: 400;
}

.popular-postes .media p i{
    margin-right: 5px;
}

/** Tags socal box start **/
.tags-socal-box .social-list {
    float: right;
    text-align: right;
}

.tags-socal-box h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.tags-socal-box .social-list ul li {
    display: inline-block;
    margin-left: 10px;
}

.tags-socal-box .social-list ul li a {
    color: #141313;
}

.tags-socal-box .social-list ul li:first-child {
    margin: 0;
}

/** Comment start **/
.comment {
    margin: 0 0 30px 0;
    position: relative;
}

.comment .comment-author {
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;
}

.comments ul {
    list-style: none;
    margin: 0 0 0 80px;
    padding: 0;
}

.comment .comment-author a img {
    bottom: 2px;
    display: block;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 0;
}

.comment .comment-content {
    margin-left: 80px;
    padding-bottom: 10px;
    border-bottom: dashed 1px #d6d6d6;
}

.comment .comment-meta-author {
    float: left;
    font-weight: 600;
    font-size: 16px;
}

.comment .comment-meta-reply {
    float: right;
    margin-left: 10px;
    font-size: 14px;
}

.comment .comment-meta-reply a{
    font-weight: 600;
}

.comment .comment-meta-date {
    float: right;
    font-size: 14px;
}

.comment .comment-body {
    margin-top: 5px;
}

.comment .comment-rating {
    color: orange;
    margin-bottom: 5px;
    font-size: 13px;
}

.comment .mrg-bdr {
    margin-bottom: 0;
    border-bottom: none;
    padding: 0;
}

/** Celebrate our love start**/
.celebrate-our-love{
    background: rgba(0, 0, 0, 0.04) url(./assets/images/img-2.png) top left repeat;
    z-index: 0;
    position: relative;
    background-size: cover;
    text-align: center;
    padding: 195px 0;
    opacity: 1;
}

.celebrate-our-love:before{
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 1;
    opacity: 0.10;
    background-color: rgba(36, 42, 53, 0.7);
}

/** Thank you start**/
.thank-you{
    padding: 195px 0;
    /* background: rgba(0, 0, 0, 0.04) url(../img/img-3.png) top left repeat; */
    background-size: cover;
    text-align: center;
    z-index: 1;
    position: relative;
    background-color: transparent;
    background-image: linear-gradient(0deg, #082E33 0%, #0C6873 100%);
}
.thank-you .social ul li {display: inline-block; padding: 0 10px;}
.thank-you .social ul li a {font-size: 24px;}
.thank-you ul li a:hover {color: #fff;}
.thank-you:before{
    position: relative;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0.10;
    color: #fff;
    background-color: rgba(36, 42, 53, 0.7);
}
.footerNewsletter 
{
    position: relative;
    top: 5rem;
}
/** countdowns start **/
.countdowns {
    background: rgba(0, 0, 0, 0.04) url(./assets/images/img-1.png) top left repeat;
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    padding: 120px 0 90px;
}

.countdowns.inner{
    display: inline-block;
    text-shadow: none;
    right: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: rgba(36, 42, 53, 0.7);
}

.countdown-inner{
    text-align: center;
    margin: 0 auto;
    max-width: 820px;
    color: #fff;
}

.countdown-box div {
    display: inline-block;
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 0 2%;
    width: 20%;
    text-shadow: 2px 2px rgb(12 11 11 / 56%);
    margin-bottom: 30px;
    background: rgb(0 0 0 / 34%);
    border-radius: 5px;
    padding: 10px 0 22px 0;
}

.countdown-box div:last-child {
    border: none;
}

.countdown-box div span {
    display: block;
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    line-height: .5;
}

/** Sidebar start **/
.sidebar {
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.form-search {
    position: relative;
}

.form-search .form-control {
    border: 1px solid #eee;
    height: 45px;
}

.form-search .btn {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px 12px 9px 12px;
}

.show-more-options {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
    display: block;
    cursor: pointer;
}

.widget {
    margin-bottom: 10px;
    padding: 20px;
}

.sidebar-title {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 600;
    color: #37404d;
}

.title-2 {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 600;
    color: #37404d;
}

/** Content area start **/
.content-area {
    padding: 120px 0 90px;
}

.content-area-2 {
    padding: 120px 0;
}

/** mb **/
.mb-60{
    margin-bottom: 60px;
}

.mb-40{
    margin-bottom: 40px;
}

.mb-30{
    margin-bottom: 30px;
}

/** Full page serch start **/
#full-page-search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);

    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    z-index: 99999;
}

#full-page-search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

#full-page-search input[type="search"] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0);
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 28px;
}

#full-page-search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
}

#full-page-search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    opacity: 1;
    padding: 10px 17px;
    font-size: 17px;
    outline: 0 auto -webkit-focus-ring-color;
    cursor: pointer;
    border-radius: 3px;
    background: #3a3a3a;
}

/** Protfolio item start **/
.portfolio-item, .portfolio-item:focus {
    outline: none;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
    text-align: center;
}

.portfolio-item img, .portfolio-item img:focus {
    outline: none;
    width: 100%;
    height: 100%;
}

.portfolio-item:hover img {
    zoom: 1;
    filter: alpha(opacity=20);
    -webkit-opacity: 0.1;
    opacity: 0.1;
}

.portfolio-item:hover .portfolio-content {
    display: inline;
    -webkit-transition-delay: 0.50s;
    transition-delay: 0.50s;
    background-color: rgba(0, 0, 0, 0.90);
    color: #fff !important;
    z-index: -999;
}

.portfolio-content {
    display: none;
    top: 0;
    width: 100%;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    color: #1c1c1c;
    height: 100%;
    position: absolute;
    right: 0;
}

.portfolio-content-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.portfolio-content p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #fff;
}

.portfolio-content h3{
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
}

.portfolio-content i{
    border-radius: 0;
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    text-align: center;
    margin: 3px;
    cursor: pointer;
    border: solid 1px #fff;
}

.filteriz-navigation {
    text-align: center;
    background: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    max-width: 430px;
    display: inline-block;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50px;
}

.filteriz-navigation li {
    color: #141313;
    float: left;
    text-align: center;
    letter-spacing: .5px;
    padding: 6px 22px 6px 22px;
    border: 0;
    border-radius: 50px;
    margin: 0;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

/** Off-canvas menu start  **/
body.off-canvas-sidebar-open {
    left: -375px;
    position: relative;
}

.off-canvas-sidebar-open .off-canvas-sidebar {
    -webkit-transform: translateX(-375px);
    transform: translateX(-375px)
}

.off-canvas-sidebar {
    background: #1b1d1f;
    position: fixed;
    right: -375px;
    top: 0;
    height: 100%;
    width: 375px;
    z-index: 999999;
    -webkit-transition: -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
    transition: -webkit-transform .2s cubic-bezier(.645, .045, .355, 1);
    transition: transform .2s cubic-bezier(.645, .045, .355, 1);
    transition: transform .2s cubic-bezier(.645, .045, .355, 1), -webkit-transform .2s cubic-bezier(.645, .045, .355, 1)
}

.off-canvas-sidebar-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 100vh;
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
}

.off-canvas-content, .off-canvas-header {
    padding-right: 30px;
    padding-left: 30px
}

.off-canvas-content {
    padding-bottom: 30px
}

.off-canvas-header {
    padding-top: 30px;
    padding-bottom: 20px;
    text-align: right;
    top: 0px;
    position: absolute;
    right: 0;
}

.canvas-widget .menu {
    text-align: center;
    margin-top: 50px;
}

.canvas-widget .menu-item {
    margin-bottom: 12px;
}

.canvas-widget .menu-item a {
    color: #fff;
    line-height: 1.2;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    transition: all .3s ease-in-out;
}

.canvas-widget .menu-item a:hover {
    opacity: .8;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.canvas-widget .logo{  }

.canvas-widget .social-icons {
    text-align: center;
    margin-top: 60px;
}

.canvas-widget .social-icons > li {
    display: inline-block;
    margin: 10px;
}

.canvas-widget .social-icons > li a {
    color: #fff;
    transition: all .3s ease-in-out;
}

.canvas-widget .social-icons > li a:hover {
    opacity: .8;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.close-offcanvas {
    font-size: 24px
}

.portfolio-area{
    overflow: hidden;
}

/** MEDIA **/
@media (max-width: 992px) {
    .no-home .main-header .navbar-expand-lg .navbar-nav .nav-link
    {
        color: #fff!important;
    }
    .no-home .main-header .navbar-expand-lg .navbar-nav .dropdown-submenu .nav-link
    {
        color: #000!important;
    }
    .open-offcanvas {
        padding: 18px 15px 15px;
    }

    .main-header .navbar-expand-lg .navbar-nav .nav-link {
        color: #c5c1c1;
    }
    .main-header .navbar-expand-lg .navbar-nav .nav-link.donate-button
    {
        color: #fff;
    }
   
    .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
        color: #c5c1c1!important;
    }

    .love-story-box h2{
        font-size: 22px;
    }

    .love-story-box h4 {
        font-size: 13px;
    }

    .open-none{
        display: none;
    }

    .main-header {
        background: #2FB4C5;
    }

    .sub-banner .breadcrumb-area {
        top: 47%;
    }

    .sub-banner .breadcrumb-area h1 {
        font-size: 27px;
    }

    .thank-you {
        padding: 150px 0;
    }

    .couple-box .detail h3{
        font-size: 28px;
    }

    .none-992{
        display: none;
    }

    .countdowns{
        padding: 90px 0 60px;
    }

    .filteriz-navigation {
        max-width: 425px;
        padding: 5px;
    }

    .filteriz-navigation li {
        padding: 5px 20px 5px 20px;
    }

    .celebrate-our-love{
        padding: 150px 0;
    }

    .content-area {
        padding: 90px 0 60px;
    }

    .content-area-2 {
        padding: 90px 0;
    }

    .love-story-box .details{
        text-align: center;
        padding: 30px;
        background: #fff;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    }

    .love-story-box .photo {
        padding: 0;
    }

    .love-story-box{
        margin-bottom: 30px;
    }

    .love-story-box:last-child{
        margin-bottom: 0;
    }

    .multi-carousel {
        padding: 0 15px 0;
    }

    .navbar-collapse {
        margin: 0px -15px;
        background: #242424;
    }

    #main-header .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
    }

    .main-header .navbar-expand-lg .navbar-nav .active .nav-link {
        background: #f9f9f9;
        border-bottom: solid 1px transparent;
    }

    .main-header .navbar-expand-lg .navbar-nav .nav-link:hover {
        
        color: #fff!important;
    }

    .main-title {
        margin-bottom: 45px;
    }

    .main-title h1 {
        font-size: 42px;
        margin-bottom: 20px;
    }

    .main-title-2 {
        text-align: center;
        margin-bottom: 45px;
    }

    .main-title-2 h1 {
        font-size: 42px;
        margin-bottom: 20px;
    }

    .main-title-3 h1{
        font-size: 42px;
    }

    .tab-box .nav-pills{
        max-width: 250px;
        padding: 5px;
    }

    .tab-box .nav-pills .nav-link {
        padding: 5px 20px 5px 20px;
    }

    .banner .banner-slider-inner h1 {
        font-size: 70px;
    }

    .banner .banner-slider-inner .typed-text {
        font-size: 70px;
    }

    .banner .banner-slider-inner h3 {
        font-size: 19px;
    }

    .banner .banner-slider-inner p{
        font-size: 19px;
    }

    .banner .banner-slider-inner .love-img img {
        height: 30px;
    }

    .navbar-light .navbar-toggler {
        border: none;
        font-size: 20px;
        font-weight: 100;
    }

    .navbar-toggler {
        padding: 0;
        width: 35px;
        height: 35px;
        font-size: 1rem;
        border-radius: 0;
        outline: none !important;
        cursor: pointer;
    }

    .main-header .logo img {
        height: 35px;
    }

    .main-header .navbar-expand-lg .navbar-nav .nav-link {
        padding: 15px 20px;
        font-size: 14px;
    }

    .setting-button {
        font-size: 15px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        left: -40px;
    }

    .sidebar-title {
        margin-bottom: 20px;
    }

    .sub-banner {
        height: 330px;
    }

    .blog .detail h3 {
        font-size: 20px;
    }

    .blog-2 .detail h3 {
        font-size: 22px;
    }

    .blog-2 .title-2{
        font-size: 22px!important;
    }

    .banner .slider-mover-left{
        width: 35px;
        height: 35px;
        line-height: 33px;
        left: 5px;
    }

    .banner .slider-mover-left i {
        font-size: 20px;
    }

    .banner .slider-mover-right{
        width: 35px;
        height: 35px;
        line-height: 33px;
        right: 5px;
    }

    .banner .slider-mover-right i {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .meeting-with-client-img
    {
        bottom: -5rem!important;
        left: 1rem!important;
    }
    .sub-banner {
        height: 300px;
    }

    .banner .banner-slider-inner h3 {
        font-size: 17px;
        letter-spacing: 1.5px;
    }

    .banner .banner-slider-inner p {
        font-size: 18px;
    }

    .banner .w-100{
        width: auto!important;
    }

    .comment {
        margin: 0 0 20px 0;
    }

    .comment .comment-content {
        margin-left: 65px;
        padding-bottom: 0;
    }

    .comment .comment-author {
        height: 50px;
        width: 50px;
    }

    .comment .comment-author a img {
        height: 50px;
        width: 50px;
    }

    .blog-2 .detail h3 {
        font-size: 20px;
    }

    .sub-banner .breadcrumb-area h1 {
        font-size: 23px;
    }

    .main-title {
        margin-bottom: 30px;
    }

    .main-title h1 {
        font-size: 34px;
        margin-bottom: 15px;
    }

    .main-title-2 {
        margin-bottom: 30px;
    }

    .main-title-2 h1 {
        font-size: 34px;
        margin-bottom: 15px;
    }

    .main-title-3 h1{
        font-size: 34px;
    }

    .thank-you {
        padding: 135px 0;
    }

    .countdowns{
        padding: 60px 0 30px;
    }

    .celebrate-our-love{
        padding: 105px 0;
    }

    .content-area {
        padding: 60px 0 30px;
    }

    .content-area-2 {
        padding: 60px 0;
    }

    .comments-section.mb-60{
        margin-bottom: 40px;
    }

    .filteriz-navigation{
        margin: 0;
    }

    .title-2 {
        margin-bottom: 20px;
        font-size: 20px;
    }

    .form-group-2{
        margin-bottom: 25px;
    }

    #particles-banner {
        height: 100vh;
    }

    .item-bg{
        height: 100vh;
    }

    .trile-none{
        display: none;
    }

    .banner .carousel-content{
        padding: 0 50px;
    }

    .multi-carousel .rightLst{
        right: 15px;
    }

    .multi-carousel .leftLst {
        left: 15px;
    }

    .multi-carousel .multi-carousel-inner .item > div img {
        width: auto;
    }

    .tabbing-box .tab-pane {
        padding-top: 0;
    }

    .rsvp .form-group {
        margin-bottom: 20px;
    }

    .comments ul {
        margin: 0;
    }

    .tags-socal-box .social-list {
        float: none;
        text-align: left;
        margin-top: 20px;
    }

    .banner .banner-slider-inner h1 {
        font-size: 50px;
    }

    .banner .banner-slider-inner .typed-text {
        font-size: 50px;
    }

    .banner .banner-slider-inner {
        top: 0%;
    }

    button:focus {
        outline: 0 auto -webkit-focus-ring-color;
    }

    .countdown-inner .btn {
        width: 40%;
        margin: auto;
        margin-top: 10px;
        border-radius: 3px;
    }

    .countdown-inner .btn:hover{
        width: 40%;
    }

    .countdown-box {
        margin-bottom: 0;
    }

    .login-footer {
        padding: 20px 15px;
        position: inherit;
        left: 0;
        bottom: 0;
    }

    .setting-button {
        font-size: 14px;
        width: 35px;
        height: 35px;
        line-height: 35px;
        left: -35px;
        color: #fff;
    }

    .tabbing-box .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
        padding: 9px 13px;
    }

    .tabbing-box .nav-tabs li {
        font-size: 11px;
    }

    .widget {
        margin-bottom: 0;
    }

    .banner .carousel-content {
        padding: 50px 10px 0;
    }

    .banner-2 .carousel-content {
        padding: 0 10px 0;
    }

    .setting-button{
        display: none;
    }

    .sub-banner .breadcrumb-area {
        top: 50%;
    }
}

@media (max-width: 580px) {
    .comment-meta-date{
        display: none;
    }

    .item-bg{
        height: 100vh;
    }

    .banner .banner-slider-inner .typed-text {
        font-size: 40px;
    }

    .couple-box .detail {
        width: 94%;
        margin: -30px 3% 30px 3%;
        padding: 25px 20px;
        border-radius: 5px;
    }

    .couple-box img {
        border-radius: 5px;
    }

    #particles-banner {
        height: 100vh;
    }

    .filteriz-navigation li {
        padding: 3px 12px 3px 12px;
        font-size: 12px;
        border-radius: 3px;
    }

    .filteriz-navigation{
        border-radius: 3px;
    }

    .tab-box .nav-pills .nav-link {
        padding: 3px 12px 3px 12px;
        font-size: 12px;
        border-radius: 3px;
    }

    .tab-box .nav-pills {
        max-width: 206px;
        border-radius: 3px;
    }

    .countdown-box div {
        padding: 0;
    }

    .countdown-box div {
        background: none;
    }

    .box-shadow {
        box-shadow: none;
    }

    .main-header .logo {
        padding: 22.5px 0;
        margin-right: 20px;
    }

    .banner .banner-slider-inner {
        padding: 10px 40px;
        top: 0%;
    }

    .navbar-toggler {
        width: 30px;
        height: 30px;
    }

    .sidebar {
        background: transparent;
    }

    .banner .banner-slider-inner h1 {
        font-size: 45px;
    }

}
@media (min-width : 992px)
{
    .navbar-expand-lg .navbar-collapse
    {
        justify-content: flex-end;
    }
}

@media (min-width: 991px) {
    .what-we-do-sec-2 .img-2 img 
    {
        position: absolute;
        top: -17rem;
        right: -1rem;
        width: 50%;
        border: 2px solid #fff;
    }
    .what-we-do-sec-2 .img-2 
    {
        position: relative
    }
    .our-history-sec-2 .img-3 
    {
        position: relative;
    }
    .our-history-sec-2 .img-3 img 
    {
        position: absolute;
        right: -4rem;
        top: -20rem;
        width: 55%;
    }
    .our-history-sec-2 .img-2 {
        text-align: left!important;
    }
    .our-history-sec-2 .img-2 img 
    {
        width: 60%!important;
    }
    .provisionalBuilder-sec-2 .img-2 img 
    {
        border: 2px solid #fff;
        position: absolute;
        top: -16rem;
        width: 40%;
    }

    .provisionalBuilder-sec-2 .img-2 
    {
        position: relative;
    }
    .provisionalBuilder-sec-2 .img-1 
    {
        text-align: left!important;
    }
    .provisionalBuilder-sec-2 .img-1 img 
    {
        width: 80%!important;
    }
    .provisionalBuilder-sec-4 .img-1
    {
        text-align: right!important;
    }
    .provisionalBuilder-sec-4 .img-1 img 
    {
        width: 80%;
    }
    .provisionalBuilder-sec-4 .img-2
    {
        position: relative
    }
    .provisionalBuilder-sec-4 .img-2 img 
    {
        width: 60%;
        position: absolute;
        top: -14rem;
        border: 2px solid #fff;
    }
    .banner img {
        /* animation: bgburnsEast 25s linear infinite alternate; */
        /* -webkit-animation: bgburnsEast 25s linear infinite alternate; */
    }

    .dropdown:hover>.dropdown-menu {
        display: block;
    }

    .dropdown .dropdown-item:hover> .dropdown-menu {
        display: block;
    }

    .dropdown-submenu:hover> .dropdown-menu {
        display: block;
    }

    .sticky-header .navbar-expand-lg .navbar-nav .nav-link {
        color: #fff;
    }
    .banner-col-2
    {
        margin: 62px 0px 130px -238px;
    }
    .businessImage {position: relative;}
    .businessImage img 
    {
        position: absolute;
        top: -5rem;
        left: -3rem;
    }
    .how-we-help-you .img-block 
    {
        position: relative;
    }
    .how-we-help-you .img-absolute 
    {
        position: relative;
    }
    .how-we-help-you .img-absolute img
    {
        left: -15.6rem;
        /* top: 4rem; */
        position: absolute;
    }
    .how-we-help-you .img-absolute-2
    {
        position: relative;
    }
    .how-we-help-you .img-absolute-2 img
    {
        position: absolute;
        top: -25rem;
        right: -3rem;
    }
}



.sticky-header {
    position: fixed;
    background: transparent;
    top: 0px;
    z-index: 999;
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, .2);
}

.sticky-header .nav-link::after {
    display: none !important;
}

.do-sticky .nav-link::after {
    display: none !important;
}

.do-sticky-2 .nav-link::after {
    display: none !important;
}



.sticky-header .open-offcanvas {
    color: #fff;
    display: inline-block;
}

.sticky-header-scroll{
    z-index: 999;
}

/* .fixed{
    position: fixed;
    top:0; left:0;
    width: 100%;
} */

.header-shrink {
    background: #2FB4C5;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    top: 0px !important;
}

.header-shrink .navbar-expand-lg .navbar-nav .nav-link {
    color: #ffffff;
    
}



.header-shrink .open-offcanvas {
    color: #515151;
}

.header-shrink .open-offcanvas i{
    color: #fff;
}

.hide-carat .nav-link::after {
    display: none !important;
}

.sticky-header .open-offcanvas{
    color: #fff;
}
.banner .b-img img {
    width: 70%;
}
.banner
{
    background-color: #2FB4C5;
}
.heading-title 
{
    color: #FBB848;
    font-size: 185px;
    font-weight: 900;
    line-height: 0.8em;
    text-shadow: 0px 3.56px 3.56px rgba(0, 0, 0, 0.25);
}
.heading-desc
{
    color: #FFFFFF;
    font-family: "Inter", Sans-serif;
    font-size: 60px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 60px;
}
.heading-brief
{
    color: #FFFFFF;
    font-family: "Inter", Sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin: 20px -150px 0px 80px;
}
.heading-signup-text
{
    color: #FFFFFF;
    font-family: "Inter", Sans-serif;
    font-size: 22px;
    position: absolute;
    width: 50%;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: -8rem;
    padding: 0px 6rem 15px 0px;
    text-align: right;
    right: 0;
}
@media (max-width : 767px)
{
    .heading-desc
    {
        font-size: 30px;
        text-align: center;
        line-height: 1em;
        margin: 0 0 0 0;
    }
    .heading-title
    {
        font-size: 100px;
    }
    
    .heading-brief
    {
        margin: 0 0px 0 0;
    }
    .heading-signup-text
    {
        margin-top: -10rem;
        width: 100%;
    }
    .b-img
    {
        margin: 0 auto;
        text-align: center;
    } 
    .banner-form .outer-box
    {
        width: 93%!important;
    }
    .about-section
    {
        padding-top: 20rem!important;
    }
    .theme-card form .input-group {
        margin-bottom: 1rem;
    }
    .how-we-help-you .icon-list-first 
    {
        width: 100%;
    }
    .how-we-help-you .img-absolute-2
    {
        position: relative;
        right: 0;
        bottom: 0rem;
        width: 60%;
    }
}
.banner-form
{
    position: relative;
    z-index: 2;
    background-color: #F0FCFE;
}
.banner-form .outer-box {
    position: relative;
    display: block;
    margin: 0 auto;
    width: auto;
    color: #000;
    text-align: center;
    top: -5rem;
    
}
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) 
{
    border-bottom: 1px solid #dee2e6;
    padding-top: 0.375rem;
}
.input-group-text
{
    background-color: transparent!important;
    border: none!important
}
.theme-card
{
    border-style: solid;
    border-width: 12px 0px 0px 0px;
    border-color: #FBB848;
    box-shadow: 0px 113px 91px 0px rgba(0, 0, 0, 0.12);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 31px 40px 40px 40px;
    z-index: 2;
    background-color: #ffffff;
}
.input-group-addon
{
    background: none;
    border: none;
    border-bottom: 1px solid #eee;

}
.input-group .form-control 
{
    border: none;
    border-bottom: 1px solid #eee;
}
.mt-3 
{
    margin-top: 3rem!important;
}
.mt-1 
{
    margin-top: 1rem;
}
.mt-6
{
    margin-top: 6rem;
}
.mt-10
{
    margin-top: 10rem;
}
.pt-3 
{
    padding-top: 3rem!important;
}
.pb-3
{
    padding-bottom: 3rem!important;
}
.pt-6 
{
    padding-top: 6rem;
}
.pb-6 
{
    padding-bottom: 6rem;
}
.pt-10{
    padding-top: 10rem;
}
.f-18
{
    font-size: 18px;
}
.bold-500 
{
    font-weight: 500;
}
.about-section 
{
    background-color: #F0FCFE;
}
.bold 
{
    font-weight: bold;
}
.f-16
{
    font-size: 16px;
}
.f-60
{
    font-size: 60px;
}
.bb {
    border-bottom: 1px solid #0C6873;
}
.w-85 
{
    width: 85%;
}
.w-clr 
{
    color: #fff;
}
.patenting-process 
{
    background-color: transparent;
    background-image: radial-gradient(at center center, #0C6873 0%, #123D44 100%);
}
.how-we-help-you ul li
{
    margin-bottom: 1rem;    
}
.how-we-help-you .icon-list-first 
{
    width: 70%;
}
.mb-3 
{
    margin-bottom: 3rem!important;
}
.no-home header 
{
    background-color: #fff;
}
.no-home .main-header .navbar-expand-lg .navbar-nav .nav-link 
{
    color: #000;
}
.no-home .main-header .navbar-expand-lg .navbar-nav .nav-link:hover
{
    color: #FBB848
}
.meeting-with-client-img 
{
    position: relative;
    bottom: 10rem;
    left: -2rem;
}
.meeting-about-us-section p 
{
    width: 90%;
}
.selectBox-dropdown
{
    background: none!important;
}
.dropdown-item.active, .dropdown-item:active
{
    background-color: #0C6873;
    color: #FBB848!important;

}
.bg-gradient 
{
    background-color: transparent;
    background-image: radial-gradient(at center center, #0C6873 0%, #123D44 100%)!important;
}
/* //////////// */
.mr-5px {
    margin-right: 5px!important;
}

.main-banner {
    background: url(./assets/images/banner.png);
    background-size: cover;
    background-position: center;
}

.title-banner {
    color: #fbb848!important;
    margin: auto!important;
    max-width: 700px;
    text-transform: capitalize!important;
}